/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
        display: none;
        width: 100%;
        -webkit-tap-highlight-color: transparent;
        position: relative;
        z-index: 1;
}

.owl-carousel .owl-stage {
        position: relative;
        -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
}

.owl-carousel .owl-stage-outer {
        position: relative;
        overflow: hidden;
        -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-item {
        position: relative;
        min-height: 1px;
        float: left;
        -webkit-backface-visibility: hidden;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
        display: block;
        width: 100%;
        -webkit-transform-style: preserve-3d;
}

#inner-box img{
  width: 25%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
        display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
        cursor: pointer;
        cursor: hand;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
}

.owl-carousel.owl-loaded {
        display: block;
}

.owl-carousel.owl-loading {
        opacity: 0;
        display: block;
}

.owl-carousel.owl-hidden {
        opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
        visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
}

.owl-carousel.owl-grab {
        cursor: move;
        cursor: grab;
}

.owl-carousel.owl-rtl {
        direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
        float: right;
}

.no-js .owl-carousel {
        display: block;
}

.owl-carousel .animated {
        -webkit-animation-duration: 1000ms;
        animation-duration: 1000ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
        z-index: 0;
}

.owl-carousel .owl-animated-out {
        z-index: 1;
}

.owl-carousel .fadeOut {
        -webkit-animation-name: fadeOut;
        animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
        0% {
                opacity: 1;
        }

        100% {
                opacity: 0;
        }
}

@keyframes fadeOut {
        0% {
                opacity: 1;
        }

        100% {
                opacity: 0;
        }
}

.owl-height {
        transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
        opacity: 0;
        transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
        position: relative;
        height: 100%;
        background: #000;
}

.owl-carousel .owl-video-play-icon {
        position: absolute;
        height: 80px;
        width: 80px;
        left: 50%;
        top: 50%;
        margin-left: -40px;
        margin-top: -40px;
        /* background: url("owl.video.play.png") no-repeat; */
        cursor: pointer;
        z-index: 1;
        -webkit-backface-visibility: hidden;
        transition: -webkit-transform 100ms ease;
        transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
        -webkit-transform: scale(1.3, 1.3);
        -ms-transform: scale(1.3, 1.3);
        transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
        display: none;
}

.owl-carousel .owl-video-tn {
        opacity: 0;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
}

ul {
        padding: 0;
        margin: 0;
        list-style: none
}

.megamenu_lists>li>a {
        padding: 10px;
        color: #000000
}

.xs-container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        max-width: 1140px
}

.xs-navbar {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
}

.xs-navbar-brand {
        text-decoration: none;
        color: #000000
}

.xs-navbar-brand>h2 {
        margin-top: 0;
        margin-bottom: 0
}

@media (min-width:992px) {
        .elementskit-header .elementskit-menu-container {
                position: static
        }

        .elementskit-megamenu-panel>li {
                display: inline-block;
                width: 25%;
                float: left
        }
}

.language-content p {
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 40px;
        font-size: rem(20px)
}

.flag-lists {
        text-align: center
}

.flag-lists li {
        display: inline-block;
        margin-right: 25px
}

.flag-lists li:last-child {
        margin-right: 0
}

.flag-lists li a {
        display: block;
        color: #FFFFFF;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden
}

.flag-lists li a:hover {
        opacity: .7
}

.flag-lists li a img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        vertical-align: middle
}

.modal-searchPanel .xs-search-group {
        position: relative
}

.modal-searchPanel .xs-search-group input:not([type=submit]) {
        height: 70px;
        background-color: transparent;
        border-radius: 50px;
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        padding: 0 30px;
        width: 100%
}

.modal-searchPanel .xs-search-group input:not([type=submit])::-webkit-input-placeholder {
        color: #FFFFFF
}

.modal-searchPanel .xs-search-group input:not([type=submit])::-moz-placeholder {
        color: #FFFFFF
}

.modal-searchPanel .xs-search-group input:not([type=submit]):-ms-input-placeholder {
        color: #FFFFFF
}

.modal-searchPanel .xs-search-group input:not([type=submit]):-moz-placeholder {
        color: #FFFFFF
}

.modal-searchPanel .xs-search-group .search-button {
        background-color: transparent;
        border: 0px;
        padding: 0;
        color: #FFFFFF;
        position: absolute;
        right: 0;
        cursor: pointer;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 100%;
        width: 70px;
        border-radius: 0 50px 50px 0
}

.modal-searchPanel .xs-search-panel {
        max-width: 800px;
        margin: 1.75rem auto
}

.mfp-bg.xs-promo-popup {
        background-color: rgba(0, 0, 0, 0.87);
        padding-bottom: 100%;
        border-radius: 100%;
        overflow: hidden;
        -webkit-animation: menu-animation .8s ease-out forwards;
        animation: menu-animation .8s ease-out forwards
}

@-webkit-keyframes menu-animation {
        0% {
                opacity: 0;
                -webkit-transform: scale(0.04) translateY(300%);
                transform: scale(0.04) translateY(300%)
        }

        40% {
                -webkit-transform: scale(0.04) translateY(0);
                transform: scale(0.04) translateY(0);
                -webkit-transition: ease-out;
                -o-transition: ease-out;
                transition: ease-out
        }

        40% {
                -webkit-transform: scale(0.04) translateY(0);
                transform: scale(0.04) translateY(0)
        }

        60% {
                opacity: 1;
                -webkit-transform: scale(0.02) translateY(0px);
                transform: scale(0.02) translateY(0px)
        }

        61% {
                opacity: 1;
                -webkit-transform: scale(0.04) translateY(0px);
                transform: scale(0.04) translateY(0px)
        }

        99.9% {
                opacity: 1;
                height: 0;
                padding-bottom: 100%;
                border-radius: 100%
        }

        to {
                opacity: 1;
                -webkit-transform: scale(2) translateY(0px);
                transform: scale(2) translateY(0px);
                height: 100%;
                padding-bottom: 0;
                border-radius: 0
        }
}

@keyframes menu-animation {
        0% {
                opacity: 0;
                -webkit-transform: scale(0.04) translateY(300%);
                transform: scale(0.04) translateY(300%)
        }

        40% {
                -webkit-transform: scale(0.04) translateY(0);
                transform: scale(0.04) translateY(0);
                -webkit-transition: ease-out;
                -o-transition: ease-out;
                transition: ease-out
        }

        40% {
                -webkit-transform: scale(0.04) translateY(0);
                transform: scale(0.04) translateY(0)
        }

        60% {
                opacity: 1;
                -webkit-transform: scale(0.02) translateY(0px);
                transform: scale(0.02) translateY(0px)
        }

        61% {
                opacity: 1;
                -webkit-transform: scale(0.04) translateY(0px);
                transform: scale(0.04) translateY(0px)
        }

        99.9% {
                opacity: 1;
                height: 0;
                padding-bottom: 100%;
                border-radius: 100%
        }

        to {
                opacity: 1;
                -webkit-transform: scale(2) translateY(0px);
                transform: scale(2) translateY(0px);
                height: 100%;
                padding-bottom: 0;
                border-radius: 0
        }
}

.xs-promo-popup .modal-content {
        background-color: transparent;
        padding: 0px;
        border: 0px
}

.xs-promo-popup .mfp-close {
        color: #FFFFFF;
        opacity: 0;
        -webkit-transition: all 1s ease .8s;
        -o-transition: all 1s ease .8s;
        transition: all 1s ease .8s;
        -webkit-transform: translateY(-500px);
        -ms-transform: translateY(-500px);
        transform: translateY(-500px)
}

.xs-promo-popup.mfp-ready .mfp-close {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
}

.xs-promo-popup.my-mfp-slide-bottom .zoom-anim-dialog {
        opacity: 0;
        -webkit-transition: all 1s ease .8s;
        -o-transition: all 1s ease .8s;
        transition: all 1s ease .8s;
        -webkit-transform: translateY(-500px);
        -ms-transform: translateY(-500px);
        transform: translateY(-500px)
}

.xs-promo-popup.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
}

.mini_cart_item {
        margin-bottom: 20px;
        padding-bottom: 20px
}

.xs-empty-content {
        padding: 120px 30px 30px
}

.xs-empty-content .widget-title {
        font-size: rem(22px)
}

.xs-empty-content .xs-title {
        margin-bottom: 20px;
        font-size: rem(18px)
}

.xs-empty-content .woocommerce-mini-cart__empty-message {
        font-size: rem(20px)
}

.xs-empty-content .empty-cart-icon {
        font-size: rem(50px)
}

.sidebar-textwidget .sidebar-logo-wraper {
        margin-bottom: 33px
}

.sidebar-textwidget>p {
        font-size: rem(15px);
        line-height: 1.5;
        margin-bottom: 40px
}

.sidebar-textwidget .social-list {
        margin-bottom: 30px
}

.sideabr-list-widget {
        margin-bottom: 40px
}

.sideabr-list-widget li {
        margin-bottom: 20px
}

.sideabr-list-widget li:last-child {
        margin-bottom: 0px
}

.sideabr-list-widget img {
        margin-right: 15px;
        margin-top: 3px
}

.sideabr-list-widget a,
.sideabr-list-widget p {
        margin-bottom: 2px;
        color: #000000;
        margin-bottom: 8px;
        display: block
}

.sideabr-list-widget span {
        display: block;
        font-size: rem(14px);
        color: #1768dd
}

.sideabr-list-widget .media-body {
        line-height: 1
}

.subscribe-from {
        position: relative;
        margin-bottom: 40px
}

.subscribe-from p {
        font-size: rem(18px);
        font-weight: 700;
        margin-bottom: 15px;
        line-height: 1;
        color: #1768dd
}

.subscribe-from label {
        position: absolute;
        bottom: -24px;
        left: 0px;
        margin-bottom: 0;
        line-height: 1
}

.subscribe-from .form-control {
        border: 1px solid #f5f5f5;
        height: 50px;
        padding-left: 25px
}

.subscribe-from .sub-btn {
        background-color: transparent;
        border: 0px;
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 25px;
        cursor: pointer;
        color: #1768dd;
        height: 100%
}

.subscribe-from .sub-btn:focus {
        outline: none
}

.subscribe-from .form-group {
        position: relative;
        overflow: hidden;
        -webkit-box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
        box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
        border-radius: 25px;
        margin-bottom: 0px
}

.xs-sidebar-group .xs-overlay {
        left: 100%;
        top: 0;
        position: fixed;
        z-index: 9999;
        height: 100%;
        opacity: 0;
        width: 100%;
        visibility: hidden;
        -webkit-transition: all .4s ease-in .8s;
        -o-transition: all .4s ease-in .8s;
        transition: all .4s ease-in .8s;
        cursor: url(../../assets/images/cross-out.png), pointer
}

.xs-sidebar-group .widget-heading {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 28px;
}
div#widget-x{        
        padding: 12px 28px !important;
}
.xs-sidebar-widget {
        position: fixed;
        right: -100%;
        top: 0;
        bottom: 0;
        width: 100%;
        max-width: 375px;
        z-index: 999999;
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        background-color: #272727;
        -webkit-transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
        -o-transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
        transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
        visibility: hidden;
        opacity: 0
}

.xs-sidebar-group.isActive .xs-overlay {
        opacity: .9;
        visibility: visible;
        -webkit-transition: all .8s ease-out 0s;
        -o-transition: all .8s ease-out 0s;
        transition: all .8s ease-out 0s;
        left: 0
}

.xs-sidebar-group.isActive .xs-sidebar-widget {
        opacity: 1;
        visibility: visible;
        right: 0;
        -webkit-transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
        -o-transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
        transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s
}

.sidebar-textwidget {
        padding: 50px 28px;
}

.close-side-widget {
        color:rgb(75, 144, 207);
        font-size: 1.6rem;
        display: block;
        font-weight: 600;
}

.sidebar-widget-container {
        position: relative;
        top: 150px;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all .3s ease-in .3s;
        -o-transition: all .3s ease-in .3s;
        transition: all .3s ease-in .3s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden
}

.xs-sidebar-group.isActive .sidebar-widget-container {
        top: 0px;
        opacity: 1;
        visibility: visible;
        -webkit-transition: all 1s ease-out 1.2s;
        -o-transition: all 1s ease-out 1.2s;
        transition: all 1s ease-out 1.2s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden
}

.xs-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: .5;
        z-index: 0
}

.xs-bg-black {
        background-color: #000000de
}

.xs-menu-tools>li {
        display: inline-block;
        margin-right: 15px
}

.xs-menu-tools>li:last-child {
        margin-right: 0
}

.xs-menu-tools>li>a {
        color: #000000;
        text-decoration: none
}

.nav-alignment-dynamic,
.nav-alignment-flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
}

.elementskit-menu-container {
        z-index: 10000
}

.elementskit-navbar-nav {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none
}

.elementskit-navbar-nav li {
        position: relative;
        padding: 0px 15px;
}

.elementskit-navbar-nav li:last-child {
        border-bottom: none;
}

.elementskit-navbar-nav li a {
        height: 100%;
        text-transform: capitalize;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-decoration: none
}

.elementskit-navbar-nav li a:hover {
        text-decoration: none
}

.elementskit-navbar-nav li.elementskit-megamenu-has {
        position: static
}

.elementskit-navbar-nav>li>a {
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
        text-transform: none;
        font-weight: 500;
        letter-spacing: normal
}

.elementskit-dropdown {
        padding-left: 0;
        list-style: none;
        opacity: 0;
        visibility: hidden;
}

.elementskit-dropdown>li>.elementskit-dropdown {
        left: 100%;
        top: 0
}

.elementskit-dropdown>li>.elementskit-dropdown.open-fromleft {
        left: auto;
        right: 100%
}

.elementskit-submenu-panel>li:hover>a,
.elementskit-submenu-panel>li>a:hover {
        color: #0d3a4f;
        background: rgba(255, 255, 255, 0.5);
        background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0.5)));
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
        background: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%)
}

.elementskit-megamenu-panel {
        width: 100%;
}

.elementskit-overlay {
        display: none
}

.elementskit-nav-identity-panel {
        display: none
}

.elementskit-menu-hamburger {
        border: 1px solid rgba(0, 0, 0, 0.2);
        float: right
}

.elementskit-menu-hamburger .elementskit-menu-hamburger-icon {
        background-color: rgba(0, 0, 0, 0.5)
}

.elementskit-menu-close,
.elementskit-menu-hamburger {
        display: none;
        padding: 8px;
        background-color: transparent;
        border-radius: 0.25rem;
        position: relative;
        z-index: 10;
        cursor: pointer;
        width: 45px
}

.elementskit-menu-close .elementskit-menu-hamburger-icon,
.elementskit-menu-hamburger .elementskit-menu-hamburger-icon {
        height: 1px;
        width: 100%;
        display: block;
        margin-bottom: 4px
}

.elementskit-menu-close .elementskit-menu-hamburger-icon:last-child,
.elementskit-menu-hamburger .elementskit-menu-hamburger-icon:last-child {
        margin-bottom: 0
}

.elementskit-menu-close {
        border: 1px solid rgba(0, 0, 0, 0.5);
        color: rgba(51, 51, 51, 0.5);
        float: right;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px
}

.elementskit-dropdown-has:hover>a:after {
        border-color: transparent #1e73be #1e73be transparent
}

.elementskit-dropdown-has>a {
        position: relative;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
}

.elementskit-dropdown-has>a:hover:after {
        border-color: transparent #1e73be #1e73be transparent
}

.elementskit-dropdown-has>a:after {}

.elementskit-submenu-panel .elementskit-dropdown-has:hover>a:after {
        border-color: transparent #0d3a4f #0d3a4f transparent
}

.elementskit-submenu-panel .elementskit-dropdown-has>a:hover:after {
        border-color: transparent #0d3a4f #0d3a4f transparent
}

@media (min-width:992px) {
        .elementskit-dropdown .elementskit-dropdown-has>a:after {}

        .elementskit-menu-container {
                background: rgba(255, 255, 255, 0);
                background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
                background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%);
                background: -o-linear-gradient(bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%);
                background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%);
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
                position: relative
        }

        .elementskit-dropdown {
                min-width: 250px
        }

        .elementskit-navbar-nav {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
        }

        .elementskit-navbar-nav>li>a {}

        .elementskit-dropdown {
                -webkit-box-shadow: 0px 10px 30px 0px rgba(45, 45, 45, 0.2);
                box-shadow: 0px 10px 30px 0px rgba(45, 45, 45, 0.2);
                position: absolute;
                top: 100%;
                left: 0px;
                -webkit-transform: translateY(-10px);
                -ms-transform: translateY(-10px);
                transform: translateY(-10px);
                pointer-events: none
        }

        .elementskit-dropdown-has:hover>.elementskit-dropdown {
                opacity: 1;
                visibility: visible;
                -webkit-transform: translateY(0px);
                -ms-transform: translateY(0px);
                transform: translateY(0px);
                pointer-events: auto
        }
}

@media (max-width:991px) {
        .elementskit-menu-container {
                background: #f7f7f7;
                background: -webkit-gradient(linear, left bottom, left top, from(#f7f7f7), to(#f7f7f7));
                background: -webkit-linear-gradient(bottom, #f7f7f7 0%, #f7f7f7 100%);
                background: -o-linear-gradient(bottom, #f7f7f7 0%, #f7f7f7 100%);
                background: linear-gradient(0deg, #f7f7f7 0%, #f7f7f7 100%);
                max-width: 350px;
                width: 100%;
                position: fixed;
                top: 0;
                left: -450px;
                height: 100%;
                -webkit-box-shadow: 0px 10px 30px 0px rgba(255, 165, 0, 0);
                box-shadow: 0px 10px 30px 0px rgba(255, 165, 0, 0);
                overflow-y: auto;
                overflow-x: hidden;
                padding-top: 10px;
                padding-left: 0px;
                padding-right: 0px;
                padding-bottom: 10px;
                -webkit-transition: left 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 0ms;
                -o-transition: left 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 0ms;
                transition: left 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 0ms
        }

        .elementskit-menu-container.active {
                left: 0
        }

        .no-scroller {
                overflow: hidden
        }

        .elementskit-menu-overlay {
                display: block;
                position: fixed;
                z-index: 5;
                top: 0;
                left: -110%;
                height: 100%;
                width: 100%;
                background-color: rgba(51, 51, 51, 0.5);
                -webkit-transition: all 0.4s ease;
                -o-transition: all 0.4s ease;
                transition: all 0.4s ease;
                opacity: 1;
                visibility: visible;
                -webkit-transition: all 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 400ms;
                -o-transition: all 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 400ms;
                transition: all 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 400ms
        }

        .elementskit-menu-overlay.active {
                left: 0%;
                -webkit-transition: all 600ms cubic-bezier(0.22, 0.61, 0.24, 1) 0ms;
                -o-transition: all 600ms cubic-bezier(0.22, 0.61, 0.24, 1) 0ms;
                transition: all 600ms cubic-bezier(0.22, 0.61, 0.24, 1) 0ms
        }

        .elementskit-menu-close,
        .elementskit-menu-hamburger {
                display: block
        }

        .elementskit-nav-identity-panel {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 5;
                width: 100%
        }

        .elementskit-nav-identity-panel .elementskit-site-title {
                float: left
        }

        .elementskit-nav-identity-panel .elementskit-menu-close {
                float: right
        }

        .elementskit-navbar-nav {
                padding-top: 100px
        }

        .elementskit-navbar-nav>li>a {
                color: #000000;
                font-size: 12px;
                padding-top: 5px;
                padding-left: 10px;
                padding-right: 5px;
                padding-bottom: 5px
        }

        .elementskit-navbar-nav>li>a:hover {
                color: #0d3a4f
        }

        .elementskit-navbar-nav>li:hover>a {
                color: #0d3a4f
        }

        .elementskit-submenu-panel>li>a {
                color: #000000;
                font-size: 12px;
                padding-top: 7px;
                padding-left: 7px;
                padding-right: 7px;
                padding-bottom: 7px
        }

        .elementskit-dropdown {
                max-height: 0;
                overflow: hidden;
                display: block;
                border: 0px;
                -webkit-box-shadow: none;
                box-shadow: none;
                -webkit-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
                -o-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
                transition: max-height 2s cubic-bezier(0, 1, 0, 1)
        }

        .elementskit-dropdown.elementskit-dropdown-open {
                max-height: 2500px;
                opacity: 1;
                visibility: visible;
                -webkit-transition: max-height 5s ease-out;
                -o-transition: max-height 5s ease-out;
                transition: max-height 5s ease-out
        }

        .elementskit-navbar-nav>.elementskit-dropdown-has>.elementskit-dropdown li a {
                padding-left: 15px
        }
}


/* Animation */
.parallax>use {
        -webkit-animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
        animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax>use:nth-child(1) {
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
        -webkit-animation-duration: 7s;
        animation-duration: 7s;
}

.parallax>use:nth-child(2) {
        -webkit-animation-delay: -3s;
        animation-delay: -3s;
        -webkit-animation-duration: 10s;
        animation-duration: 10s;
}

.parallax>use:nth-child(3) {
        -webkit-animation-delay: -4s;
        animation-delay: -4s;
        -webkit-animation-duration: 13s;
        animation-duration: 13s;
}

.parallax>use:nth-child(4) {
        -webkit-animation-delay: -5s;
        animation-delay: -5s;
        -webkit-animation-duration: 20s;
        animation-duration: 20s;
}
