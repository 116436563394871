/* NextBit HTML Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Style Two
5. Banner Section / Style Two
6. Appointment Section / Two
7. Services Section / Two
8. Pricing Section / Two
9. Facility Section
10. Internet Section / Two
11. Testimonial Section
12. Clients Section
13. Featured Section
14. Store Section
15. CTA Section
16. Main Footer Section
17. Network Section
18. Faq Section
19. Streming Section
20. News Section
21. Page Title Section
22. Team Section
23. Movie Section
24. Services Detail Page Section
25. Services Widgets Section
26. Oir Products Section
27. Shop Detail Section
28. Cart Section
29. Checkout Section
30. Blog Detail Section
31. Blog Sidebar Widgets
32. Not Found / 404
33. Contact Info Section
34. Map Section
35. Contact Form Section

**********************************************/

/* 

	font-family: 'Poppins', sans-serif;
	
*/

/*** 

====================================================================
	Root Code Variables
====================================================================

 ***/

/* Theme Color */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');

:root {

    /* #df0303 in decimal RGB */
    --main-color: #4B90CF;

    /* #010c3a in decimal RGB */
    --main-color-two: rgb(1, 12, 58);

    /* #00162b in decimal RGBA */
    --main-color-rgba: rgb(223, 3, 3, 0.40);

    /* #ffffff in decimal RGB */
    --white-color: #ffffff;

    /* #ffffff in decimal RGBA */
    --white-color-opicity: rgba(255, 255, 255, 0.80);

    /* #ffffff in decimal RGBA Two */
    --white-color-opicity-two: rgba(255, 255, 255, 0.40);

    /* #000000 in decimal RGB */
    --black-color: rgb(0, 0, 0);

    /* #ffffff in decimal RGBA Two */
    --black-color-opicity: #404040;

    /* #ffffff in decimal RGBA Three */
    --black-color-opicity-two: #404040;

    /* #252525 in decimal RGB */
    --dark-color: #404040;

    /* #222222 in decimal RGB */
    --dark-color-two:#404040;

    /* #010101 Heading Color in decimal RGB */
    --heading-color: #404040;

    /* #010101 Dark Color Two in decimal RGB */
    --dark-color-two: #404040;


    /* Fonts */
    --font-family-poppins: 'Poppins', sans-serif;


    /* Fonts Size's */

    --font-10: 10px;
    --font-12: 12px;
    --font-13: 13px;
    --font-14: 14px;
    --font-15: 15px;
    --font-16: 16px;
    --font-18: 18px;
    --font-20: 20px;
    --font-22: 22px;
    --font-24: 24px;
    --font-26: 26px;
    --font-28: 28px;
    --font-30: 30px;
    --font-34: 34px;
    --font-36: 36px;
    --font-40: 40px;
    --font-42: 42px;
    --font-44: 44px;
    --font-48: 48px;
    --font-50: 50px;
    --font-52: 52px;
    --font-54: 54px;
    --font-56: 56px;
    --font-58: 58px;
    --font-60: 60px;
    --font-62: 62px;
    --font-64: 64px;
    --font-66: 66px;
    --font-68: 68px;
    --font-70: 70px;
    --font-72: 72px;
    --font-74: 74px;
    --font-76: 76px;
    --font-78: 78px;
    --font-80: 80px;
    --font-90: 90px;
    --font-100: 100px;
    --font-110: 110px;
    --font-120: 120px;
    --font-150: 150px;
    --font-200: 200px;

    --margin-zero: 0px;

    /* Margin Left Variables */
    --margin-left-5: 5px;
    --margin-left-10: 10px;
    --margin-left-15: 15px;
    --margin-left-20: 20px;
    --margin-left-25: 25px;
    --margin-left-30: 30px;
    --margin-left-35: 35px;
    --margin-left-40: 40px;
    --margin-left-45: 45px;
    --margin-left-50: 50px;
    --margin-left-55: 55px;
    --margin-left-60: 60px;
    --margin-left-65: 65px;
    --margin-left-70: 70px;
    --margin-left-75: 75px;
    --margin-left-80: 80px;
    --margin-left-85: 85px;
    --margin-left-90: 90px;
    --margin-left-95: 95px;
    --margin-left-100: 100px;

    /* Margin Top Variables */
    --margin-top-5: 5px;
    --margin-top-10: 10px;
    --margin-top-15: 15px;
    --margin-top-20: 20px;
    --margin-top-25: 25px;
    --margin-top-30: 30px;
    --margin-top-35: 35px;
    --margin-top-40: 40px;
    --margin-top-45: 45px;
    --margin-top-50: 50px;
    --margin-top-55: 55px;
    --margin-top-60: 60px;
    --margin-top-65: 65px;
    --margin-top-70: 70px;
    --margin-top-75: 75px;
    --margin-top-80: 80px;
    --margin-top-85: 85px;
    --margin-top-90: 90px;
    --margin-top-95: 95px;
    --margin-top-100: 100px;

    /* Margin Right Variables */
    --margin-right-5: 5px;
    --margin-right-10: 10px;
    --margin-right-15: 15px;
    --margin-right-20: 20px;
    --margin-right-25: 25px;
    --margin-right-30: 30px;
    --margin-right-35: 35px;
    --margin-right-40: 40px;
    --margin-right-45: 45px;
    --margin-right-50: 50px;
    --margin-right-55: 55px;
    --margin-right-60: 60px;
    --margin-right-65: 65px;
    --margin-right-70: 70px;
    --margin-right-75: 75px;
    --margin-right-80: 80px;
    --margin-right-85: 85px;
    --margin-right-90: 90px;
    --margin-right-95: 95px;
    --margin-right-100: 100px;
    --margin-right-110: 110px;

    /* Margin Bottom Variables */
    --margin-bottom-5: 5px;
    --margin-bottom-10: 10px;
    --margin-bottom-15: 15px;
    --margin-bottom-20: 20px;
    --margin-bottom-25: 25px;
    --margin-bottom-30: 30px;
    --margin-bottom-35: 35px;
    --margin-bottom-40: 40px;
    --margin-bottom-45: 45px;
    --margin-bottom-50: 50px;
    --margin-bottom-55: 55px;
    --margin-bottom-60: 60px;
    --margin-bottom-65: 65px;
    --margin-bottom-70: 70px;
    --margin-bottom-75: 75px;
    --margin-bottom-80: 80px;
    --margin-bottom-85: 85px;
    --margin-bottom-90: 90px;
    --margin-bottom-95: 95px;
    --margin-bottom-100: 100px;
    --margin-bottom-150: 150px;
    --margin-bottom-200: 200px;


    --padding-zero: 0px;

    /* Padding Left Variables */
    --padding-left-5: 5px;
    --padding-left-10: 10px;
    --padding-left-15: 15px;
    --padding-left-20: 20px;
    --padding-left-25: 25px;
    --padding-left-30: 30px;
    --padding-left-35: 35px;
    --padding-left-40: 40px;
    --padding-left-45: 45px;
    --padding-left-50: 50px;
    --padding-left-55: 55px;
    --padding-left-60: 60px;
    --padding-left-65: 65px;
    --padding-left-70: 70px;
    --padding-left-75: 75px;
    --padding-left-80: 80px;
    --padding-left-85: 85px;
    --padding-left-90: 90px;
    --padding-left-95: 95px;
    --padding-left-100: 100px;
    --padding-left-100: 110px;
    --padding-left-120: 120px;
    --padding-left-130: 130px;
    --padding-left-140: 140px;
    --padding-left-150: 150px;
    --padding-left-160: 160px;
    --padding-left-170: 170px;
    --padding-left-180: 180px;
    --padding-left-190: 190px;
    --padding-left-200: 200px;
    --padding-left-240: 240px;
    --padding-left-290: 290px;

    /* Padding Top Variables */
    --padding-top-5: 5px;
    --padding-top-10: 10px;
    --padding-top-15: 15px;
    --padding-top-20: 20px;
    --padding-top-25: 25px;
    --padding-top-30: 30px;
    --padding-top-35: 35px;
    --padding-top-40: 40px;
    --padding-top-45: 45px;
    --padding-top-50: 50px;
    --padding-top-55: 55px;
    --padding-top-60: 60px;
    --padding-top-65: 65px;
    --padding-top-70: 70px;
    --padding-top-75: 75px;
    --padding-top-80: 80px;
    --padding-top-85: 85px;
    --padding-top-90: 90px;
    --padding-top-95: 95px;
    --padding-top-100: 100px;
    --padding-top-110: 110px;
    --padding-top-120: 120px;
    --padding-top-130: 130px;
    --padding-top-140: 140px;
    --padding-top-150: 150px;

    /* Padding Right Variables */
    --padding-right-5: 5px;
    --padding-right-10: 10px;
    --padding-right-15: 15px;
    --padding-right-20: 20px;
    --padding-right-25: 25px;
    --padding-right-30: 30px;
    --padding-right-35: 35px;
    --padding-right-40: 40px;
    --padding-right-45: 45px;
    --padding-right-50: 50px;
    --padding-right-55: 55px;
    --padding-right-60: 60px;
    --padding-right-65: 65px;
    --padding-right-70: 70px;
    --padding-right-75: 75px;
    --padding-right-80: 80px;
    --padding-right-85: 85px;
    --padding-right-90: 90px;
    --padding-right-95: 95px;
    --padding-right-100: 100px;
    --padding-right-110: 110px;
    --padding-right-150: 150px;
    --padding-right-160: 160px;
    --padding-right-170: 170px;
    --padding-right-180: 180px;
    --padding-right-200: 200px;
    --padding-right-220: 220px;
    --padding-right-240: 240px;
    --padding-right-250: 250px;
    --padding-right-260: 260px;

    /* Padding Bottom Variables */
    --padding-bottom-5: 5px;
    --padding-bottom-10: 10px;
    --padding-bottom-15: 15px;
    --padding-bottom-20: 20px;
    --padding-bottom-25: 25px;
    --padding-bottom-30: 30px;
    --padding-bottom-35: 35px;
    --padding-bottom-40: 40px;
    --padding-bottom-45: 45px;
    --padding-bottom-50: 50px;
    --padding-bottom-55: 55px;
    --padding-bottom-60: 60px;
    --padding-bottom-65: 65px;
    --padding-bottom-70: 70px;
    --padding-bottom-75: 75px;
    --padding-bottom-80: 80px;
    --padding-bottom-85: 85px;
    --padding-bottom-90: 90px;
    --padding-bottom-95: 95px;
    --padding-bottom-100: 100px;
    --padding-bottom-150: 150px;
    --padding-bottom-200: 200px;
    --padding-bottom-250: 250px;

}

/*** 

====================================================================
	Reset
====================================================================

 ***/

* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
}

/*** 

====================================================================
	Global Settings
====================================================================

***/

body {
    font-family: var(--font-family-poppins);
    font-size: 14px;
    color: var(--dark-color);
    line-height: 1.6em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
}

.bordered-layout .page-wrapper {
    padding: 0px 50px 0px;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: var(--main-color);
}
 a.btn-style-one:hover{
    color:white !important
}
button,
a:hover,
a:focus,
a:visited {
    text-decoration: none;
    outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    margin: 0px;
    background: none;
    font-weight: 700;
    color: var(--heading-color);
    font-family: var(--font-family-poppins);
}

input,
button,
select,
textarea {}

textarea {
    overflow: hidden;
}

a {
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.text,
p {
    position: relative;
    line-height: 1.8em;
    font-size: var(--font-15);
    color: var(--dark-color);
    font-family: var(--font-family-poppins);
}
.ten-section p {
    color: #54595F;
    font-family: "Roboto", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}
/* Typography */

h1 {
    line-height: 1.1em;
    font-size: var(--font-80);
}

h2 {
    line-height: 1.2em;
    font-size: var(--font-42);
}

h3 {
    line-height: 1.3em;
    font-size: var(--font-36);
}

h4 {
    line-height: 1.4em;
    font-size: var(--font-24);
}

h5 {
    line-height: 1.4em;
    font-size: var(--font-22);
}

h6 {
    line-height: 1.6em;
    font-size: var(--font-18);
}

.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

.medium-container {
    position: static;
    max-width: 1000px;
    padding: 0px 15px;
    margin: 0 auto;
}

.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

img {
    display: inline-block;
    max-width: 100%;
}

.theme-btn {
    cursor: pointer;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.centered {
    text-align: center;
}

::-webkit-input-placeholder {
    color: inherit;
}

::-moz-input-placeholder {
    color: inherit;
}

::-ms-input-placeholder {
    color: inherit;
}
input:disabled {
    background: #ccc !important;
    cursor:not-allowed;
}
.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999999999999999;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}

.preloaderbutton, .preloadercard {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;

}
.preloaderbutton #lottie svg {
    height: 30px !important;
}



/***

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    color: var(--main-color);
    font-size: var(--font-16);
    text-transform: uppercase;
    line-height: 38px;
    text-align: center;
    z-index: 100;
    cursor: pointer;
    background: var(--white-color);
    display: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.scroll-to-top:hover {
    color: var(--white-color);
    background: var(--main-color);
}
#arrow-back:hover {
    color: #0056b3;
}
/* List Style One */

.list-style-one {
    position: relative;
}

.list-style-one li {
    position: relative;
    font-weight: 400;
    line-height: 1.8em;
    color: var(--white-color);
    font-size: var(--font-14);
    margin-bottom: var(--margin-bottom-10);
    padding-left: var(--padding-left-30);
}

/* a.btn-recover-password:hover, a.btn-criar-conta-black:hover {
    color: #ffffff;
} */

a.btn-recover-password, a.recover-password-check, a.btn-criar-conta {
    margin-top: 0.75rem;
    font-size: 0.8rem;
}

.list-style-one li a {
    position: relative;
    color: var(--white-color);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.list-style-one li a:hover {
    color: var(--main-color);
}

.list-style-one li .icon {
    position: absolute;
    left: 0px;
    top: 5px;
    color: var(--main-color);
    font-size: var(--font-18);
    line-height: 1em;
    font-weight: 300;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

/* List Style Two */

.list-style-two {
    position: relative;
}

.list-style-two li {
    position: relative;
    font-weight: 400;
    line-height: 1.8em;
    color: var(--dark-color);
    font-size: var(--font-14);
    margin-bottom: var(--margin-bottom-5);
    padding-left: var(--padding-left-30);
}

.list-style-two li:before {
    position: absolute;
    left: 0px;
    content: "\f187";
    font-weight: 600;
    color: var(--main-color);
    font-family: "Flaticon";
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

/* Btn Style One */

.btn-style-one {
    position: relative;
    display: inline-grid;
    font-size: var(--font-16);
    line-height: 30px;
    color: var(--white-color);
    font-weight: 600;
    overflow: hidden;
    background: none;
    text-align: center;
    border-radius: 4px;
    padding: 5px 30px 5px;
    text-transform: capitalize;
    background-color: var(--main-color);
}
.btn-style-one:disabled {
    cursor:not-allowed;
}

.btn-style-one-copy {
    position: relative;
    display: inline-grid;
    font-size: var(--font-16);
    line-height: 30px;
    color: black;
    font-weight: 600;
    overflow: hidden;
    background: none;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0px 5px;
    text-transform: capitalize;
    background-color:#cccccc;
}

.btn-style-one:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
    background-color: var(--white-color);
}

.btn-style-one .txt {
    position: relative;
    z-index: 1;
}

/* .btn-style-one:hover::before {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-one:hover {
    color: var(--main-color);
} */
/* Btn Style Two */

.btn-style-two {
    position: relative;
    line-height: 30px;
    font-weight: 500;
    overflow: hidden;
    background: none;
    text-align: center;
    border-radius: 3px;
    display: inline-grid;
    padding: 13px 40px 13px;
    font-size: var(--font-16);
    text-transform: capitalize;
    color: var(--white-color);
    background-color: var(--main-color);
}

.btn-style-two:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
    background-color: var(--white-color);
}

.btn-style-two:hover::before {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-two .txt {
    position: relative;
    z-index: 1;
}

.btn-style-two i {
    position: relative;
    top: 2px;
    font-style: normal;
    line-height: 1em;
    margin-left: 5px;
}

.btn-style-two:hover {
    color: var(--main-color-two);
}

/* Btn Style Three */

.btn-style-three {
    position: relative;
    line-height: 30px;
    font-weight: 500;
    overflow: hidden;
    background: none;
    text-align: center;
    border-radius: 3px;
    display: inline-grid;
    padding: 13px 35px 13px;
    font-size: var(--font-16);
    text-transform: capitalize;
    color: var(--main-color);
    background-color: var(--white-color);
}

.btn-style-three:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
    background-color: var(--main-color);
}

.btn-style-three:hover::before {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-three .txt {
    position: relative;
    z-index: 1;
}

.btn-style-three i {
    position: relative;
    top: 2px;
    font-style: normal;
    line-height: 1em;
    margin-left: 5px;
}

.btn-style-three:hover {
    color: var(--white-color);
}

/* Btn Style Four */

.btn-style-four {
    position: relative;
    line-height: 30px;
    font-weight: 500;
    overflow: hidden;
    background: none;
    text-align: center;
    border-radius: 3px;
    display: inline-grid;
    padding: 13px 40px 13px;
    font-size: var(--font-16);
    text-transform: capitalize;
    color: var(--white-color);
    background-color: var(--main-color);
}

.btn-style-four:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
    background-color: var(--main-color-two);
}

.btn-style-four:hover::before {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-four .txt {
    position: relative;
    z-index: 1;
}

.btn-style-four i {
    position: relative;
    top: 2px;
    font-style: normal;
    line-height: 1em;
    margin-left: 5px;
}

.btn-style-four:hover {
    color: var(--white-color);
}

/* Btn Style Five */

.btn-style-five {
    position: relative;
    line-height: 30px;
    font-weight: 500;
    overflow: hidden;
    background: none;
    text-align: center;
    border-radius: 3px;
    display: inline-grid;
    padding: 13px 44px 13px;
    font-size: var(--font-16);
    text-transform: capitalize;
    color: var(--white-color);
    background-color: var(--main-color-two);
}

.btn-style-five:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
    background-color: var(--white-color);
}

.btn-style-five:hover::before {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-five .txt {
    position: relative;
    z-index: 1;
}

.btn-style-five i {
    position: relative;
    top: 2px;
    font-style: normal;
    line-height: 1em;
    margin-left: 5px;
}

.btn-style-five:hover {
    color: var(--main-color);
}
/* Btn Style six */

.btn-style-six {
    position: relative;
    line-height: 30px;
    font-weight: 500;
    overflow: hidden;
    background: none;
    text-align: center;
    border-radius: 3px;
    display: inline-grid;
    padding: 5px 38px 5px;
    font-size: var(--font-16);
    text-transform: capitalize;
    color: var(--white-color);
    background-color: var(--black-color-opicity-two);
}

.btn-style-six:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
    background-color: #00000050;
}

/* .btn-style-six:hover::before {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
} */

.btn-style-six .txt {
    position: relative;
    z-index: 1;
    color: white;
}

.btn-style-six i {
    position: relative;
    top: 2px;
    font-style: normal;
    line-height: 1em;
    margin-left: 5px;
}

/* .btn-style-six:hover {
    color: var(--main-color);
} */
/* Btn Style seven */

.btn-style-seven {
    position: relative;
    line-height: 30px;
    font-weight: 500;
    overflow: hidden;
    background: none;
    text-align: center;
    border-radius: 3px;
    display: inline-grid;
    padding: 5px 38px 5px;
    font-size: var(--font-16);
    text-transform: capitalize;
    color: var(--white-color);
    background-color: var(--main-color);
}

.btn-style-seven:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
    background-color: #155f87;
}

/* .btn-style-seven:hover::before {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
} */

.btn-style-seven .txt {
    position: relative;
    z-index: 1;
    color: var(--white-color);
}

.btn-style-seven i {
    position: relative;
    top: 2px;
    font-style: normal;
    line-height: 1em;
    margin-left: 5px;
}

/* .btn-style-seven:hover {
    color: var(--main-color);
} */
.theme_color {
    color: #0060ff;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title {
    position: relative;
    margin-bottom: var(--margin-bottom-40);
}

.sec-title .separator {
    position: relative;
    width: 88px;
    height: 5px;
    background-color: var(--main-color);
    margin-bottom: var(--margin-bottom-25);
}

.sec-title .separator:before {
    position: absolute;
    content: '';
    left: -1px;
    top: -2px;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    display: inline-block;
    background-color: var(--main-color);
}

.sec-title .separator:after {
    position: absolute;
    content: '';
    right: -1px;
    top: -2px;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    display: inline-block;
    background-color: var(--main-color);
}

.sec-title h2 {}

.sec-title .title {
    position: relative;
    font-weight: 600;
    letter-spacing: 1px;
    color: var(--main-color);
    font-size: var(--font-18);
    text-transform: uppercase;
    margin-bottom: var(--margin-bottom-15);
}

.sec-title-plans .title {
    margin-bottom: 0;
}

.sec-title-plans .title:hover {
    border-bottom: 4px solid;
    cursor: pointer;
}

.select {
    border-bottom: 4px solid;
    cursor: pointer;
}

.sec-title .text {
    margin-top: var(--margin-top-15);
}

.sec-title.light .text,
.sec-title.light h2 {
    color: #e0e0e0;
}

.sec-title.centered {
    text-align: center !important;
}

.sec-title.centered .separator {
    margin: 0 auto;
    margin-bottom: var(--margin-bottom-25);
}

.nao-mostrar {
    display: none;
}

.mostrar {
    display: flex;
}

.mostrarAss {
    display: block;
}

.main-slider video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: calc(100vh - 150px);
}

.ten-section .selo{
    padding-bottom: 40px;
    margin-bottom: 1rem;
}
.header-page{
    padding-top: 85px;
}
.modal-dialog{
    width: 100%;
}
.modal-body{
    font-size: 1.5rem;
}
.modal{
    z-index: 999999;
}
.modal-title{
    color: var(--main-color);
}
.modal-open .modal{
    overflow-y: scroll;
}

.Toastify__toast-container{
    z-index: 99999999999 !important;
}
.input::-webkit-input-placeholder, textarea#textArea::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #bababa !important;
}

.input:-moz-placeholder,  textarea#textArea::-webkit-input-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #bababa !important;
    opacity: 1;
}

.input::-moz-placeholder,  textarea#textArea::-webkit-input-placeholder { /* Mozilla Firefox 19+ */
    color: #bababa !important;
    opacity: 1;
}

.input:-ms-input-placeholder,  textarea#textArea::-webkit-input-placeholder { /* Internet Explorer 10-11 */
    color: #bababa !important;
}

.input::-ms-input-placeholder,  textarea#textArea::-webkit-input-placeholder { /* Microsoft Edge */
    color: #bababa !important;
}
#img-cielo{
    display: flex;
    justify-content: center;
    align-items: center;
}
#img-cielo img{
    width: 40%;
}
#imgs_bandeira {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
#imgs_bandeira img{
   width: 33%;
}
#signup_partner{
    background: url(../../assets/images/background/pattern-2.png);
}

.cc-window.cc-banner.cc-type-info.cc-theme-classic.cc-bottom{
    z-index: 9999999 !important;
}
.modal-dialog{
    margin: 0;
    padding: 10px;
}
.modal-header{
    align-items: flex-start;
}

.modal-footer{
    padding: 0.75rem 0.75rem 0.75rem 1.25rem;
}
.preloader>body{
overflow: hidden;
}