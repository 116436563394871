
/*** 

====================================================================
	Main Footer
====================================================================

***/

.main-footer{
    background: #111111 url(../../assets/images/background/diagmonds.png) repeat center center;
	padding-top: 30px;
    padding-bottom: 30px;
    font-size: 13px;
    font-weight: 300;
}
#contato .menu-footer {
    margin-bottom: 40px;
}
#contato .menu-footer li {
    list-style: none;
    display: inline-block;
    margin-right: 35px;
}
#contato a {
    color: #888888;
}
#contato a:hover {
    color: #155F87;
}
#contato .social{
	display: flex;
	justify-content: center;
}
#contato .social a{
	margin: 13px;
}
#contato h3 {
    color: #e0e0e0;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 0;
    margin-bottom: 20px;
}
#contato p {
    color: #888888;
	margin: 0;

}
#contato .social {
    margin-top: 40px;
    text-align: center;
}
#contato .social a{
	width: 64px;
    height: 64px;
}
#contato .facebook{
    background: #111111 url(../../assets/images/icons/Face_1.png) no-repeat center center;
	background-size: cover;
}
#contato .facebook:hover{
    background: #111111 url(../../assets/images/icons/Face_2.png) no-repeat center center;
	background-size: cover;
}
#contato .youtube{
    background: #111111 url(../../assets/images/icons/YouTube_1.png) no-repeat center center;
	background-size: cover;
}
#contato .youtube:hover{
    background: #111111 url(../../assets/images/icons/YouTube_2.png) no-repeat center center;
	background-size: cover;
}
#contato .instagram{
    background: #111111 url(../../assets/images/icons/Insta_1.png) no-repeat center center;
	background-size: cover;
}
#contato .instagram:hover{
    background: #111111 url(../../assets/images/icons/Insta_2.png) no-repeat center center;
	background-size: cover;
}
.main-footer .pattern-layer-one{
	position:absolute;
	left:0px;
	bottom:140px;
	width:449px;
	height:350px;
	background-repeat:no-repeat;
}

.main-footer .pattern-layer-two{
	position:absolute;
	right:0px;
	bottom:140px;
	width:472px;
	height:388px;
	background-repeat:no-repeat;
}

.main-footer .widgets-section{
	position:relative;
	text-align:center;
}

.main-footer .logo{
	position:relative;
	line-height:1em;
	/* margin-bottom:var(--margin-bottom-50); */
}

.main-footer .contact-info-list{
	position:relative;
}

.main-footer .contact-info-list li{
	position:relative;
	text-align:left;
	line-height:1.9em;
	color:var(--white-color);
	padding-left:var(--padding-left-60);
	margin-right:var(--margin-right-100);
	margin-bottom:var(--margin-bottom-30);
	display:inline-table;
}

.main-footer .contact-info-list li:last-child{
	margin-right:var(--margin-zero);
}

.main-footer .contact-info-list li a{
	position:relative;
	display:inline-block;
	color:var(--white-color);
}

.main-footer .contact-info-list li .icon{
	position:absolute;
	left:0px;
	top:0px;
	line-height:1em;
}

.main-footer .contact-info-list li a:hover{
	color:var(--main-color);
}

.main-footer .social-box{
	position:relative;
	/* margin-top:var(--margin-top-40); */
}

.main-footer .social-box li{
	position:relative;
	margin:0px 6px;
	display:inline-block;
}

.main-footer .social-box li a{
	position:relative;
	width:40px;
	height:40px;
	line-height:40px;
	border-radius:50px;
	display:inline-block;
	color:var(--white-color);
	background-color:var(--main-color);
}

.main-footer .social-box li a:hover{
	color:var(--main-color);
	background-color:var(--white-color);
}

.main-footer .footer-bottom{
    background: black;
    padding: 18px 0;
    margin-top: 30px;
}

#contato .copyright{
	color: #888888;
    text-align: center;
    margin-bottom: 0;
	overflow-wrap: break-word;
	font-family: "Roboto", sans-serif;
	font-size: var(--font-13);
}
