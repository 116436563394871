/*** 

====================================================================
	Header style One
====================================================================

***/

.main-header{
	position:fixed;
	z-index:99;
	width:100%;
	background-color:var(--white-color);
}

/* Header Top */
.bg-light {
    background-color: #ffffff !important;
}
.main-header .header-top{
	position:relative;
	overflow:hidden;
	background-color:var(--main-color-two);
}

.main-header .header-top .top-left{
	position: relative;
	float:left;
}

.main-header .header-top .info{
	position:relative;
	padding:13px 0px;
}

.main-header .header-top .info li{
	position:relative;
	display:inline-block;
	font-size:var(--font-15);
	color:var(--white-color);
	margin-right:var(--margin-right-20);
}

.main-header .header-top .info li .icon{
	position:absolute;
	left:0px;
	color:var(--white-color);
}

.main-header .header-top .info li:last-child{
	margin-right:0px;
}

.main-header .header-top .info li a{
	position:relative;
	color:var(--white-color);
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	padding-left:var(--padding-left-25);
}

.main-header .header-top li a:hover {
	color:var(--main-color);
}

.main-header .header-top .social-box{
	position: relative;
	padding:13px 0px;
}

.main-header .header-top .social-box:before{
	position: absolute;
	content:'';
	left:-170px;
	top:0px;
	right:-1200px;
	bottom:0px;
	 transform: skewX(-30deg);
	background-color:var(--main-color);
}

.main-header .header-top .social-box li{
	position: relative;
	display:inline-block;
	margin-left:var(--margin-left-25);
}

.main-header .header-top .social-box li a{
	position: relative;
	color:var(--white-color);
	font-size:var(--font-16);
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .header-top .social-box li a:hover{
	color:var(--main-color-two);
}

.main-header .header-upper{
	position:relative;
	background-color:var(--white-color);
}

.main-header .main-box{
	position:relative;
	padding:0px 0px;
	left:0px;
	top:0px;
	width:100%;
	background:none;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .main-box .outer-container{
	position:relative;
	padding:0px 40px;
}

.main-header .logo-box{
	position:relative;
	float:left;
	z-index:10;
}
.logo-box .logo{
	padding: 25px 0px;
}
.main-header .logo-box .logo img{
	display:inline-block;
	max-width:100%;
	height: 35px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.main-header .header-lower{
	position:relative;
}

.main-header .header-lower .nav-outer{
	position:relative;
	float:right;
}

.main-header .header-upper .logo-box{
	position: relative;
	padding:10px 0px 10px;
}

.main-header .header-upper .logo-box .logo{
	position:relative;
}

/* Main Menu */

.main-header .main-menu{
	position:relative;
	float:left;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .main-menu .navbar-collapse{
	padding:0px;
	float: left;
	display:block !important;
}

.main-header .main-menu .navigation{
	position:relative;
	margin:0px;
}

.main-header .sticky-header .main-menu .navigation > li:hover > a,
.main-header .sticky-header .main-menu .navigation > li.current > a{
	color:var(--main-color);
}

.main-header .sticky-header .nav-outer .options-box{
	margin-top:var(--margin-top-40);
}

/*Sticky Header*/

.main-header .sticky-header{
	position:fixed;
	opacity:0;
	visibility:hidden;
	left:0px;
	top:0px;
	width:100%;
	padding:0px 0px;
	z-index:0;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	background-color:var(--white-color);
	box-shadow:0px 0px 10px rgba(0,0,0,0.10);
}

.main-header .sticky-header .mobile-nav-toggler{
	display:none;
	cursor:pointer;
	color:var(--black-color);
	font-size:var(--font-30)
}

.main-header .sticky-header .logo{
	padding:30px 0px;
}

.main-header.fixed-header .sticky-header{
	z-index:999;
	opacity:1;
	visibility:visible;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-op-animation-name: fadeInDown;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-ms-animation-duration: 500ms;
	-moz-animation-duration: 500ms;
	-op-animation-duration: 500ms;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-ms-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-op-animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-ms-animation-iteration-count: 1;
	-moz-animation-iteration-count: 1;
	-op-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;	
}

.main-header .main-menu .navigation > li > a, a.link-header{
	position:relative;
	display:block;
	text-align:center;
	line-height:30px;
	letter-spacing:0px;
	opacity:1;
	font-weight:600;
	padding:25px 10px;
	font-size:var(--font-16);
	color:var(--dark-color) !important;
	text-transform: capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .main-menu .navigation > li:last-child{
	margin-right:var(--margin-zero);
}

.main-header .main-menu .navigation > li:last-child > a{
	padding-right:0px;
}

.main-header .sticky-header .main-menu .navigation > li{
	position:relative;
}

.main-header .sticky-header .main-menu .navigation > li:last-child{
	margin-right:var(--margin-zero);
}

.main-header .main-menu .navigation > li:last-child ul{
	right:0px;
}

.main-header .main-menu .navigation > li:hover > a,
.main-header .main-menu .navigation > li.current > a, a.link-header:hover{
	opacity:1;
	color:var(--main-color) !important;
}

.main-header .main-menu .navigation > li > ul{
	position:absolute;
	width:15rem;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	-o-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: top;
	-ms-transform-origin: top;
	-o-transform-origin: top;
	transform-origin: top;
	opacity: 0;
	visibility: hidden;
    visibility: hidden;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
	border-radius:0px;
	background-color:var(--main-color);
	box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
}

.main-header .main-menu .navigation > li > ul.from-right{
	left:auto;
	right:0px;	
}

.main-header .main-menu .navigation > li > ul > li{
	position:relative;
	width:100%;
	text-align:left;
	border-bottom:1px solid rgba(255,255,255,0.10);
}

.main-header .main-menu .navigation > li > ul > li:last-child{
	border-bottom:none;
}

.main-header .main-menu .navigation > li > ul > li > a{
	position:relative;
	display:block;
	padding:12px 18px;
	line-height:24px;
	font-weight:500;
	font-size:var(--font-15);
	text-transform:capitalize;
	color:var(--white-color);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .main-menu .navigation > li > ul > li > a:before{
	position:absolute;
	content:'//';
	left:10px;
	top:11px;
	opacity:0;
	font-weight:700;
	font-size:var(--font-12);
	color:var(--main-color);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .main-menu .navigation > li > ul > li:hover > a::before{
	opacity:1;
}

.main-header .sticky-header .main-menu .navigation > li > a:before{
	
}

.main-header .main-menu .navigation > li > ul > li:hover > a{
	padding-left:30px;
	color:var(--white-color);
	background-color:var(--main-color-two);
}

.main-header .main-menu .navigation > li > ul > li.dropdown > a:after{
	font-family: 'FontAwesome';
	content: "\f105";
	position:absolute;
	right:15px;
	top:13px;
	width:10px;
	height:20px;
	display:block;
	color:var(--white-color);
	line-height:20px;
	font-size:var(--font-16);
	font-weight:normal;
	text-align:center;
	z-index:5;	
}

.main-header .main-menu .navigation > li > ul > li > ul{
	position:absolute;
	left:100%;
	top:0px;
	width:15rem;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	-o-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: top;
	-ms-transform-origin: top;
	-o-transform-origin: top;
	transform-origin: top;
	opacity: 0;
	visibility: hidden;
    visibility: hidden;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
	border-radius:0px;
	background-color:var(--main-color);
	box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
}

.main-header .main-menu .navigation > li > ul > li > ul.from-right{
	left:auto;
	right:0px;	
}

.main-header .main-menu .navigation > li > ul > li > ul > li{
	position:relative;
	width:100%;
	text-align:left;
	border-bottom:1px solid rgba(255,255,255,0.10);
}

.main-header .main-menu .navigation > li > ul > li > ul > li:last-child{
	border-bottom:none;	
}

.main-header .main-menu .navigation > li > ul > li > ul > li > a{
	position:relative;
	display:block;
	padding:12px 18px;
	line-height:24px;
	font-weight:500;
	font-size:var(--font-15);
	text-transform:capitalize;
	color:var(--white-color);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .main-menu .navigation > li > ul > li > ul > li:hover > a{
	color:var(--white-color);
	background-color:var(--white-color-two);
}

.main-header .main-menu .navigation > li > ul > li > ul > li > a:before{
	position:absolute;
	content:'//';
	left:10px;
	top:11px;
	opacity:0;
	font-weight:700;
	font-size:var(--font-12);
	color:var(--main-color);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .main-menu .navigation > li > ul > li > ul > li:hover > a::before{
	opacity:1;
}

.main-header .main-menu .navigation > li > ul > li > ul > li:hover > a{
	padding-left:30px;
	color:var(--white-color);
	background-color:var(--main-color-two);
}

.main-header .main-menu .navigation > li.dropdown:hover > ul{
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	-o-transform: scaleY(1);
	transform: scaleY(1);
	opacity: 1;
	visibility: visible;
}

.main-header .main-menu .navigation li > ul > li.dropdown:hover > ul{
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	-o-transform: scaleY(1);
	transform: scaleY(1);
	opacity: 1;
	visibility: visible;
}

.main-header .main-menu .navbar-collapse > ul li.dropdown .dropdown-btn{
	position:absolute;
	right:10px;
	top:6px;
	width:30px;
	height:30px;
	text-align:center;
	color:var(--white-color);
	line-height:28px;
	border:1px solid var(--white-color-opicity-two);
	background-size:20px;
	cursor:pointer;
	z-index:5;
	display:none;
}

.main-menu .navigation > li > ul > li.updates{
	padding:12px 15px;
	color:#ffffff;
	left:0px;
	right:0px;
	width:auto;
	font-weight:600;
	font-size:var(--font-16);
	background-color:var(--main-color);
}

/*** 

====================================================================
	Mobile Menu
====================================================================

***/

.main-header .nav-outer .mobile-nav-toggler{
	position: relative;
	float: right;
	padding:2px 0 0px;
	font-size: var(--font-26);
	line-height: 44px;
	cursor: pointer;
	color:var(--black-color);
	display: none;
}

.mobile-menu{
	position: fixed;
	right: 0;
	top: 0;
	width: 300px;
	padding-right:30px;
	max-width:100%;
	height: 100%;
	visibility: hidden;
	z-index: 999999;
}

.mobile-menu .nav-logo{
	position:relative;
	padding:20px 20px;
	text-align:left;	
}

.mobile-menu .nav-logo img{
	max-width:200px;
}

.mobile-menu-visible{
	overflow: hidden;
}

.mobile-menu-visible .mobile-menu{
	visibility: visible;
}

.mobile-menu .menu-backdrop{
	position: fixed;
	right: 0;
	top: 0;
	width: 0%;
	height: 100%;
	z-index: 1;
	background: rgba(0,0,0,0.90);
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.mobile-menu-visible .mobile-menu .menu-backdrop{
	opacity: 1;
	width:100%;
	visibility: visible;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
	

}

.mobile-menu .menu-box{
	position: absolute;
	right: -400px;
	top: 0px;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow-y: auto;
	background: #ffffff;
	padding: 0px 0px;
	z-index: 5;
	border-radius: 0px;
	
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
}

.mobile-menu-visible .mobile-menu .menu-box{
	right:0px;
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.mobile-menu .close-btn{
	position: absolute;
	right: 15px;
	top: 15px;
	line-height: 30px;
	width: 30px;
	text-align: center;
	font-size: 14px;
	color: #202020;
	cursor: pointer;
	z-index: 10;
	-webkit-transition:all 0.5s ease;
	-moz-transition:all 0.5s ease;
	-ms-transition:all 0.5s ease;
	-o-transition:all 0.5s ease;
	transition:all 0.5s ease;
	-webkit-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	transform: translateY(-50px);
}

.mobile-menu-visible .mobile-menu .close-btn{
	-webkit-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition-delay: 900ms;
	-moz-transition-delay: 900ms;
	-ms-transition-delay: 900ms;
	-o-transition-delay: 900ms;
	transition-delay: 900ms;
}

.mobile-menu .close-btn:hover{
	opacity: 0.50;
}

.mobile-menu .navigation{
	position: relative;
	display: block;
	width:100%;
	border-top: 1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li{
	position: relative;
	display: block;
	border-bottom: 1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li > ul > li:last-child{
	border-bottom: none;
}

.mobile-menu .navigation li > ul > li:first-child{
	border-top: 1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li > a{
	position: relative;
	display: block;
	line-height: 24px;
	padding: 10px 20px;
	font-size: 15px;
	color: #404040;
	text-transform: capitalize;
}

.mobile-menu .navigation li:hover > a,
.mobile-menu .navigation li.current > a{
	color:#111111;
}

.mobile-menu .navigation li.dropdown .dropdown-btn{
	position:absolute;
	right:0px;
	top:0px;
	width:44px;
	height:44px;
	text-align:center;
	font-size:16px;
	line-height:44px;
	color:#404040;
	cursor:pointer;
	z-index:5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn:after{
	content:'';
	position:absolute;
	left:0px;
	top:10px;
	width:1px;
	height:24px;
	border-left:1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul{
	display: none;
}

.mobile-menu .navigation li > ul > li > a{
	padding-left:30px;
}

.mobile-menu .navigation li > ul > li > ul{
	padding-left:0px;
}

.mobile-menu .navigation li > ul > li > ul > li > a{
	padding-left:40px;
}
.main-header #logout{
	display: none;
}
.main-header .mobile-menu .close-btn:hover{
	opacity: 0.50;
}

.main-header .mobile-menu .navigation{
	position: relative;
	display: block;
	width:100%;
	border-top: 1px solid #dddddd;
}

.main-header .mobile-menu .navigation li{
	position: relative;
	display: block;
	border-bottom: 1px solid #dddddd;
}

.main-header .mobile-menu .navigation li > ul > li:last-child{
	border-bottom: none;
}

.main-header .mobile-menu .navigation li > ul > li:first-child{
	border-top: 1px solid #dddddd;
}

.main-header .mobile-menu .navigation li > a{
	position: relative;
	display: block;
	line-height: 24px;
	padding: 10px 20px;
	font-size: var(--font-16);
	color: var(--dark-color);
	text-transform: capitalize;
}

.main-header .mobile-menu .navigation li:hover > a,
.main-header .mobile-menu .navigation li.current > a{
	color: var(--dark-text-color-two);
}

.main-header .mobile-menu .navigation li.dropdown .dropdown-btn{
	position:absolute;
	right:0px;
	top:0px;
	width:44px;
	height:44px;
	text-align:center;
	color: var(--dark-color);
	font-size: var(--font-16);
	line-height:44px;
	cursor:pointer;
	z-index:5;
}

.main-header .mobile-menu .navigation li.dropdown .dropdown-btn:after{
	content:'';
	position:absolute;
	left:0px;
	top:10px;
	width:1px;
	height:24px;
	border-left:1px solid #dddddd;
}

.main-header .mobile-menu .navigation li > ul,
.main-header .mobile-menu .navigation li > ul > li > ul{
	display: none;
}

.main-header .sticky-header .navbar-header{
	display:none;
}

.main-header .outer-box{
	position: relative;
	float:right;
	padding: 27px 0px;
	/* margin-left:var(--margin-left-35); */
	display: flex;
    align-items: center;
}

/* Search Box Outer */

.main-header .search-box-outer, .login-box-outer{
	position:relative;
	float:left;
	margin-right:var(--margin-right-20);
	padding-right:var(--padding-right-20);
}

.main-header .search-box-btn, .login-box-btn{
	position:relative;
	top: 0px;
	display:block;
	float:left;
	font-size:var(--font-20);
	color:var(--main-color-two);
	line-height:20px !important;
	cursor:pointer;
	background:none;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .outer-box .nav-btn{
	position:relative;
	float:left;
	cursor:pointer;
	line-height:1em;
	font-size:var(--font-22);
	color:var(--main-color-two);
	margin-left:var(--margin-left-35);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .outer-box .nav-btn:hover,
.main-header .outer-box .search-box-btn:hover, .login-box-btn:hover{
	color:var(--main-color);
}

/* Cart Box */
.main-header .exit{
	cursor: pointer;
}
.main-header .cart-box, .usuario-box{
	position:relative;
	float:left;
	border-left:1px solid #999999;
	line-height:1em;
	background:none;
	padding-top:3px;
	display:inline-block;
	color:var(--main-color);
	font-size:var(--font-16);
	margin-left:var(--margin-left-20);
	padding-left:var(--padding-left-20);
}

.main-header .cart-box .cart-box-btn{
	background:none;
	cursor: pointer;
	color:var(--main-color);
	font-size:var(--font-24);
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-header .cart-box .cart-box-btn:after{
	display:none;
}

.main-header .cart-box .cart-box-btn .total-cart{
	position:absolute;
	right: -8px;
	top: -8px;
	width:18px;
	height:18px;
	color:var(--white-color);
	font-size:var(--font-10);
	font-weight:700;
	border-radius:50%;
	text-align:center;
	line-height:18px;
	background-color:var(--dark-color-two);
}

.main-header .cart-box .cart-box-btn:hover{
	
}

.main-header .cart-product{
	position:relative;
	margin-bottom:var(--margin-bottom-20);
	min-height:90px;
	border-bottom:1px solid #e4e4e4;
}

.main-header .cart-product .inner{
	position:relative;
	padding-left:var(--padding-left-90);
	min-height:70px;
}

.main-header .cart-product .inner .cross-icon{
	position:absolute;
	right:0px;
	top:-2px;
	z-index:1;
	cursor:pointer;
	color:var(--dark-color);
	font-size:var(--font-18);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .cart-product .inner .cross-icon:hover{
	color:var(--main-color);
}

.main-header .cart-product .inner .image{
	position:absolute;
	width:70px;
	height:70px;
	left:0px;
	top:0px;
}

.main-header .cart-product .inner h3{
	position:relative;
	font-weight:600;
	letter-spacing: 1px;
	font-size:var(--font-15);
	margin:var(--margin-zero);
	text-transform:capitalize;
}

.main-header .cart-product .inner h3 a{
	color:var(--dark-color-two);
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-header .cart-product .inner h3 a:hover{
	color:var(--main-color);
}

.main-header .cart-product .inner .quantity-text{
	position:relative;
	font-weight:300;
	color:var(--dark-color);
	font-size: var(--font-14);
	margin-top:var(--margin-top-5);
	margin-bottom: var(--margin-zero);
}

.main-header .cart-product .inner .price{
	position:relative;
	font-weight:700;
	font-size:var(--font-16);
	margin-top:var(--margin-top-5);
	color:var(--dark-color);
}

.main-header .cart-box .cart-panel{
	left: auto !important;
	right: 0px !important;
	border-radius:0px;
	padding:20px 20px;
	min-width:300px;
	margin-top:var(--margin-top-60);
	z-index: -1;
	box-shadow: 0px 0px 25px rgba(0,0,0,0.15);
	border:3px solid var(--main-color);
	-webkit-transform: translate3d(0px, 0px, 0px) !important;
	transform: translate3d(0px, 0px, 0px) !important;
}

.main-header .cart-box .cart-panel .cart-total{
	color:var(-dark-color-two);
	font-size:var(--font-15);
	font-weight:400;
	text-align:center;
	text-transform:capitalize;
}

.main-header .cart-box .cart-panel .cart-total span{
	font-weight:800;
	font-size:var(--font-16);
}

.main-header .cart-box .cart-panel .btns-boxed{
	position:relative;
	text-align:center;
	margin-top:var(--margin-top-20);
}

.main-header .cart-box .cart-panel .btns-boxed li{
	position:relative;
	margin:0px 5px;
	display:inline-block;
}

.main-header .cart-box .cart-panel .btns-boxed li a{
	position:relative;
	color:var(--white-color);
	font-weight:500;
	font-size:var(--font-12);
	padding:10px 20px 8px;
	border-radius: 2px;
	display:inline-block;
	background-color:var(--main-color);
	border:1px solid var(--main-color);
	text-transform:uppercase;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-header .cart-box .cart-panel .btns-boxed li a:hover{
	color:var(--main-color);
	background:none;
}

.main-header .cart-box .icon a{
	color:var(--main-color);
}

/*** 

====================================================================
	Search Popup
====================================================================

***/


.search-popup{
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	z-index: 99999;
	margin-top: -540px;
	transform: translateY(-100%);
    -webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: all 1500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
}

.search-popup .color-layer{
	position: absolute;
	content:'';
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba(0,0,0,0.90);
    -webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: all 1500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
}

.sidenav-bar-visible .search-popup{
	width: 80%;
}

.search-popup:after{
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	height: 560px;
	margin-top: 0px;
	content: "";
}

.search-active .search-popup{
	transform: translateY(0%);
	margin-top:var(--margin-zero);
}

.search-popup .close-search{
	position: absolute;
	left: 0;
	right: 0;
	top: 75%;
	margin: 0 auto;
	margin-top: -200px;
	border-radius: 50%;
	text-align: center;
	background-color:var(--main-color);
	width: 70px;
	cursor:pointer;
	border-bottom: 3px solid var(--white-color);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	opacity: 0;
	visibility: hidden;
}

.search-popup .close-search span{
	position: relative;
	display: block;
	height: 70px;
	width: 70px;
	font-size:var(--font-30);
	line-height: 70px;
	color: var(--white-color);
}

.search-active .search-popup .close-search{
	visibility: visible;
	opacity: 1;
	top: 50%;
	-webkit-transition-delay: 1500ms;
	-moz-transition-delay: 1500ms;
	-ms-transition-delay: 1500ms;
	-o-transition-delay: 1500ms;
	transition-delay: 1500ms;
}

.search-popup form{
	position: absolute;
	max-width: 700px;
	top: 50%;
	left: 15px;
	right: 15px;
	z-index:999;
	margin:-35px auto 0;
	transform: scaleX(0);
	transform-origin: center;
	background-color: var(--dark-color);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.search-active .search-popup form{
	transform: scaleX(1);
	-webkit-transition-delay: 1200ms;
	-moz-transition-delay: 1200ms;
	-ms-transition-delay: 1200ms;
	-o-transition-delay: 1200ms;
	transition-delay: 1200ms;
}

.search-popup .form-group{
	position:relative;
	margin:var(--margin-zero);
	overflow: hidden;
}

.search-popup .form-group input[type="text"],
.search-popup .form-group input[type="search"]{
	position:relative;
	display:block;
	font-size:var(--font-18);
	line-height: 50px;
	color:var(--black-color);
	height:70px;
	width:100%;
	padding: 10px 30px;
	background-color: var(--white-color);
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
	font-weight:500;
	text-transform:capitalize;
}

.search-popup .form-group input[type="submit"],
.search-popup .form-group button{
	position:absolute;
	right:30px;
	top:0px;
	height:70px;
	line-height: 70px;
	background: transparent;
	text-align:center;
	font-size:var(--font-24);
	color:var(--black-color);
	padding: 0;
	cursor:pointer;
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.search-popup .form-group input[type="submit"]:hover,
.search-popup .form-group button:hover{
	color: var(--black-color);
}

.search-popup input::placeholder,
.search-popup textarea::placeholder{
	color: var(--black-color);
}

.search-popup .close-search.style-two{
	position: absolute;
	right: 25px;
	left: auto;
	color: var(--white-color);
	width:auto;
	height:auto;
	top:25px;
	margin:0px;
	border:none;
	background:none !important;
	box-shadow:none !important;
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.search-popup .close-search.style-two span{
	font-size:var(--font-20);
	color: var(--white-color);
}

.main-header .mobile-menu .menu-box .mCSB_scrollTools{
	width:3px;
}

.header-style-two{
	position:absolute;
	top:50px;
	left:0px;
	right:0px;
}

.header-style-two .header-lower .inner-container{
	padding:0px 40px;
	background-color:var(--white-color);
}

/*** 

====================================================================
	Hidden Sidebar style
====================================================================

***/

.sidebar-info-contents{
	position:relative;
}

.sidebar-info-contents .content-inner{
	position:relative;
}

.sidebar-info-contents .content-inner .logo {
	padding-bottom:var(--padding-bottom-20);
}

.sidebar-info-contents .content-inner .logo img{
	display:inline-block;
	max-width:100%;	
}

.sidebar-info-contents .content-inner .content-box{
	position:relative;
}

.sidebar-info-contents .content-inner h5{
	color:var(--white-color);
	margin-bottom:var(--margin-top-20);
}

.sidebar-info-contents .content-inner .content-box .text{
	color:var(--white-color);
	margin-bottom:var(--margin-top-25);
}

.sidebar-info-contents .content-inner .contact-info{
	position:relative;
	margin-top:var(--margin-top-60);
}

.sidebar-info-contents .content-inner .social-box{
	position:relative;
	margin-top:var(--margin-top-20);
	margin-bottom:var(--margin-top-30);
}

.sidebar-info-contents .content-inner .social-box li{
	position:relative;
	display:inline-block;
	margin-right:var(--margin-right-5);
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
}

.sidebar-info-contents .content-inner .social-box li a{
	position:relative;
	width:36px;
	height:36px;
	color:var(--main-color-two);
	z-index:1;
	font-size:var(--font-14);
	line-height:36px;
	text-align:center;
	border-radius:50%;
	display:inline-block;
	background-color:var(--white-color);
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.sidebar-info-contents .content-inner .social-box li a:hover{
	color:var(--white-color);
	background-color:var(--main-color);
}

.close-side-widget,
.close-side-widget:hover, .close-side-widget-singIn,
.close-side-widget-singIn:hover{
	color:rgb(75, 144, 207) !important;
	font-size:1.6rem;
	font-weight: 600;
}
.icon-login{
	display: none;
}
